<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5 class="p-m-0">Payment Receipts</h5>
                <DataTable :value="paymentreceiptlist" :lazy="true" :paginator="true" :rows="30"
                    :totalRecords="totalRecords" :loading="loading" @page="onPage" class="p-datatable-users" data-key="bd1"
                    :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Total Amount : <span class="indigo-color"> {{
                                formatCurrency(this.totalCountOfBr20 ? this.totalCountOfBr20 : '0') }}</span>
                            </h5>
                        </template>
                        <template v-slot:right>
                            <!-- <Button label="Filter" icon="pi pi-filter" class=" p-mr-2" @click="receiptFilterDialogOpen" /> -->
                            <Button v-if="!showFillFilter" label="Filter" icon="pi pi-filter"
                                @click="receiptFilterDialogOpen()"></Button>
                            <Button v-if="showFillFilter" label="Filter" icon="pi pi-filter-slash" class="p-button-success"
                                @click="receiptFilterDialogOpen()"></Button>

                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column header="Sr." headerStyle="width: 4%">
                        <template #body="{ index }">
                            <div>{{ genrateSrNo(index) }}</div>
                        </template>
                    </Column>
                    <Column field="receiptdate" header="Receipt Date" headerStyle="width: 14%">
                        <template #body="{ data }">
                            <div>{{ format_timestamp(data.br5) }}</div>
                        </template>
                    </Column>
                    <Column field="paymentdate" header="Payment Date" headerStyle="width: 14%">
                        <template #body="{ data }">
                            <div>{{ format_date(data.br6) }}</div>
                        </template>
                    </Column>
                    <Column header="Client Name" headerStyle="width: 25%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.br3 }}</div>
                        </template>
                    </Column>
                    <Column header="Payment Mode" headerStyle="width: 11%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">
                                <div v-if="data.br7 == 1"> Payment Gateway </div>
                                <div v-else-if="data.br7 == 2"> Cheque </div>
                                <div v-else-if="data.br7 == 3"> NEFT / RTGS</div>
                                <div v-else-if="data.br7 == 4"> IMPS</div>
                                <div v-else> NEFT / RTGS </div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Amount" headerStyle="width: 18%" bodyStyle="width: 18%" class="p-jc-end">
                        <template #body="{ data }">
                            <div class="muted-text">{{ formatCurrency(data.br20) }}</div>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 14%" >
                        <template #body="{ data }">
                            <Button icon="pi pi-undo" v-tooltip.left="'Regenrate Payment Receipt'"
                                class="p-button-rounded p-button-success p-ml-2 p-mr-2" style="margin: right 2.5rem;"
                                @click="regeneratePaymentReceipt(data)" />

                            <a v-if="data.br16 != '' && data.br16 != null" class="download-btn" target="_blank"
                                :href="data.br16"><i class="pi pi-download"></i></a>
                            <span v-if="data.br16 == '' || data.br16 == null">-</span>
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
    <!-- Filter dialog start here -->
    <Dialog v-model:visible="filterclientStatus" :style="{ width: '600px' }" header="Filter" :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                    <label for="paymentstatus">Payment Status</label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="success" name="option" v-bind:value="1" v-model="paymentstatus"
                            :checked="paymentstatus == 1" />
                        <label for="success" class="p-mr-2">Payment Success</label>
                        <RadioButton id="failed" name="option" v-bind:value="2" v-model="paymentstatus"
                            :checked="paymentstatus == 2" />
                        <label for="failed" class="p-mr-2">Payment Failed</label>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-12">
                    <label for="clientname">
                        Select Client
                    </label>
                    <Dropdown id="clientname" v-model="clientname" :options="clientList" optionLabel="label"
                        placeholder="Select ..." appendTo="body"></Dropdown>
                </div>
                <div class="p-field p-col-6">
                    <label for="paymentfromdate">Payment From Date</label>
                    <Calendar :showIcon="true" :monthNavigator="true" :yearNavigator="true" v-model="paymentfromdate"
                        :maxDate="todaysDate" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                        :yearRange="'2020:' + new Date().getFullYear()" appendTo="body" :manualInput="false"></Calendar>
                </div>
                <div class="p-field p-col-6">
                    <label for="paymenttodate">Payment To Date</label>
                    <Calendar :showIcon="true" :monthNavigator="true" :yearNavigator="true" v-model="paymenttodate"
                        :minDate="paymentfromdate" :maxDate="todaysDate" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                        :yearRange="'2020:' + new Date().getFullYear()" appendTo="body" :manualInput="false"></Calendar>
                </div>
                <div class="p-field p-col-6">
                    <label for="receiptfromdate">Receipt From Date</label>
                    <Calendar :showIcon="true" :monthNavigator="true" :yearNavigator="true" v-model="receiptfromdate"
                        :maxDate="todaysDate" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                        :yearRange="'2020:' + new Date().getFullYear()" appendTo="body" :manualInput="false"></Calendar>
                </div>
                <div class="p-field p-col-6">
                    <label for="receipttodate">Receipt To Date</label>
                    <Calendar :showIcon="true" :monthNavigator="true" :yearNavigator="true" v-model="receipttodate"
                        :minDate="receiptfromdate" :maxDate="todaysDate" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                        :yearRange="'2020:' + new Date().getFullYear()" appendTo="body" :manualInput="false"></Calendar>
                </div>

            </div>
        </div>
        <template #footer>
            <div>
                <Button label="Reset" icon="pi pi-refresh" class="p-button-text" @click="reSetFilteredData()" />
                <Button v-if="!showLoader" label="Search" icon="pi pi-search" class="p-button-text p-ml-auto"
                    @click="filterclient" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- Filter dialog end here -->
</template>
<script>
import ApiService from '../service/ApiService';
import moment from "moment";
export default {
    data() {
        return {
            paymentreceiptlist: [],
            page_no: 0,
            totalRecords: 0,
            loading: false,
            filterclientStatus: false,
            paymentfromdate: '',
            paymenttodate: '',
            receiptfromdate: '',
            receipttodate: '',
            clientname: '',
            clientList: [],
            paymentstatus: '',
            showLoader: false,
            todaysDate: new Date(),
            totalCountOfBr20: 0,
            showFillFilter: false,
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        //this.loading = true;
        this.getPaymentReceipts();
    },
    methods: {
        getPaymentReceipts(ev) {
            this.loading = true;
            this.ApiService.getPaymentReceipts(ev).then((data) => {
                if (data.status == 200) {
                    this.paymentreceiptlist = data.data;
                    this.totalRecords = data.count;
                    this.totalCountOfBr20 = data.totalCountOfBr20;
                    this.loading = false;
                } else {
                    this.paymentreceiptlist = '';
                    this.totalRecords = 0;
                    this.loading = false;
                }

            });
        },
        regeneratePaymentReceipt(ev) {
            this.loading = true;
            this.ApiService.regeneratePaymentReceipt(ev).then((data) => {
                if (data.status == 200) {
                    var successMsg = data.message;
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    this.getPaymentReceipts();
                } else {
                    var errorMsg = data.message;
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    this.getPaymentReceipts();
                }
            });
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            let payload = {
                page_no: event.page,
                clientname: this.clientname,
                paymentstatus: this.paymentstatus,
                paymentfromdate: this.paymentfromdate,
                paymenttodate: this.paymenttodate,
                receiptfromdate: this.receiptfromdate,
                receipttodate: this.receipttodate
            };
            this.getPaymentReceipts( payload );
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        filterclient() {
            this.filterclientStatus = false;

            if (this.receiptfromdate) {
                this.receiptfromdate = moment(this.receiptfromdate).format("YYYY-MM-DD");
            }
            if (this.receipttodate) {
                this.receipttodate = moment(this.receipttodate).format("YYYY-MM-DD");
            }

            if (this.paymentfromdate) {
                this.paymentfromdate = moment(this.paymentfromdate).format("YYYY-MM-DD");
            }
            if (this.paymenttodate) {
                this.paymenttodate = moment(this.paymenttodate).format("YYYY-MM-DD");
            }

            let payload = {
                clientname: this.clientname,
                paymentstatus: this.paymentstatus,
                paymentfromdate: this.paymentfromdate,
                paymenttodate: this.paymenttodate,
                receiptfromdate: this.receiptfromdate,
                receipttodate: this.receipttodate
            };
            this.getPaymentReceipts(payload);
        },
        receiptFilterDialogOpen() {
            this.filterclientStatus = true;
            this.ApiService.getClientListOnly().then((data) => {
                if (data.status == 200) {
                    this.clientList = data.data;
                } else {
                    this.clientList = null;
                }
                this.showFillFilter = true;
            });
        },
        reSetFilteredData() {
            this.clientname = '';
            this.paymentstatus = '';
            this.paymentfromdate = '';
            this.paymenttodate = '';
            this.receiptfromdate = '';
            this.receipttodate = '';
            this.getPaymentReceipts();
            this.showFillFilter = false;
            this.filterclientStatus = false;
        },
        formatCurrency(amount, currency = 'INR', minimumFractionDigits = 2) {
            let formatterAmount = new Intl.NumberFormat('en-IN', { minimumFractionDigits }).format(amount.toString());
            return (+0).toLocaleString('en-IN', { style: 'currency', currency })
                .replace('0.00', " " + formatterAmount)
        },
        format_datetime(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return '-';
            }
        },
        format_timestamp(value) {
            if (value) {
                var localTime = new Date(value * 1000).toUTCString();
                // return moment(localTime).format("DD/MM/YYYY @ hh:mm A");

                return moment(localTime).format("DD/MM/YYYY");
            } else {
                return '-';
            }
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY");
            } else {
                return '-';
            }
        },
    },
};
</script>
<style scoped lang="scss">
.download-btn {
    padding: 10px;
    border-radius: 50%;
    height: 2.75rem;
    color: #ffffff;
    background: #3c6895;
    border: 0 none;
    width: 38.5px;
    display: inline-block;
}
</style>
